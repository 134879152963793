import { makeStyles } from "@material-ui/core/styles";
import loginBackground from "../../assets/img/login_bg.jpg";
import errorIcon from "../../assets/img/errorIcon.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "100%",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    backgroundImage: `url(${loginBackground})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    position: "relative",
    color: "#000000",
    "& h2": {
      color: "#000000",
    }
  },
  bgLogo: {
    position: "absolute",
    maxWidth: "50px",
    top: "50px",
    right: "50px"
  },
  paper: {
    marginTop: "0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submitContainer: {
    float: "left",
    padding: "25px 0",
    justifyContent: "center",
    flexWrap: "wrap",
    display:"flex"
  },
  SignUpLink: {
    marginTop: "13px",
    lineHeight: "1.5rem",
    fontSize: ".8rem",
    color: "#000000",
    "& span": {
      fontWeight: "700",
      color: "#7719BC",
      fontSize: ".8rem",
      display: "flex",
      display: "block"
    }
  },
  textContainer: {
    float: "left",
    padding: "0 0 25px",
    flexWrap: "wrap",
    display:"flex",
    borderBottom: "1px solid #C9DAE6",
    "& ol" : {
      paddingLeft: "15px"
    },
    "& h4":{
      margin: "0"
    }
  },
  submit: {
    minWidth: "125px",
    minHeight: "40px",
    background: "#7719BC",
    "& span": {
      color: "#ffffff"
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loginContainer: {
    maxWidth: "100%",
    padding: "50px 10px",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    /*justifyContent: "center"*/
  },
  loginAccessContainer: {
    display: "flex",
    flex: "inherit",
    background: "#e34141",
    color: "#ffffff",
    justifyContent: "center",
    minWidth: "850px",
    flexDirection: "column",
    position: "relative",
    padding: "10px 40px",
    borderRadius: "45px",
    marginBottom: "50px",
    fontSize: "1.05rem",
    "& button": {
      color: "#ffffff",
      width: "auto",
      background: "none",
      position: "absolute",
      right: "12px",
      top: "7px",
      boxShadow: "none",
      fontWeight: "700",
      fontSize: "1.2rem",
      padding: "0"
    },
    "& h2": {
      fontWeight: "700",
      color: "#ffffff",
      padding: "0",
      margin: "0 0 5px 0",
      textAlign: "center"
    }
  },
  login: {
    flex: "inherit",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    boxShadow: "0px 0px 11px 0px #d9e2f1",
    minHeight: "518px",
    background: "#f5f8fa",
    borderRadius: "10px",
    //backgroundImage: `linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(${loginbg1})`,
    backgroundSize: "contain",
    padding: "45px"
  },
  errorStyle: {
    fontSize: ".7rem",
    float: "left",
    border: "1px solid #7719BC",
    padding: "10px",
    marginTop: "-25px",
    marginBottom: "20px",
    borderRadius: "5px",
    background: "rgb(253, 236, 234)",
    "& span": {
      fontSize: ".8rem",
      display: "block",
      marginBottom: "5px",
      color: "red",
      background: `url(${errorIcon})`,
      backgroundPosition: "left",
      backgroundRepeat: "no-repeat",
      paddingLeft: "16px",
      "& svg": {
        float: "left",
        margin: "2px 2px 0 0"
      },
    },
    "& a": {
      color: "#7719BC",
      fontWeight: "700"
    },
  },
  h2: {
    fontSize: "20px",
    margin: "15px 0 10px",
    fontWeight: 700,
    color: "#525252",
    textAlign: "left"
  },
  headingTitle: {
    paddingBottom: "20px",
    display: "block",
    float: "left",
    width: "100%",
    textAlign: "left",
    borderBottom: "1px solid #C9DAE6",
    color: "#000000"

  },
  inputError: {
    fontSize: ".7rem",
    fontWeight: "200",
    color: "red",
    display: "block",
    marginTop: "-7px"
  },
  formInputField: {
    minHeight: "65px",
    margin: "8px 0",
    "& .Mui-error input": {
      borderColor: "#7719BC"
    },
    "& input": {
      padding: "10px",
      fontSize: ".8rem",
      height: "24px"
    },
    "& p": {
      background: `url(${errorIcon})`,
      backgroundPosition: "left",
      backgroundRepeat: "no-repeat",
      marginLeft: "0",
      paddingLeft: "16px"
    },
  },
  formInputLable: {
    color: "#000000",
    fontSize: ".8rem"
  },
  formInput: {
    margin: "0",
    "& p": {
      background: `url(${errorIcon})`,
      backgroundPosition: "left",
      backgroundRepeat: "no-repeat",
      marginLeft: "0",
      paddingLeft: "16px"
    },
  },
  copyText: {
    color: "#1F36C7",
    display: "block",
    textAlign: "center",
    margin: "30px 0 0 0"
  }
}));

export default useStyles;
