import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Link from '@material-ui/core/Link';
import HelpRoundedIcon from "@material-ui/icons/HelpRounded";
import { useTranslation } from "react-i18next";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useDispatch } from "react-redux";
import validator from "validator";
import AuthService from "../../../services/auth.services";
import { authForm } from "../../../actions/authActionCreator";
import Container from "@material-ui/core/Container";
import InputLabel from "@material-ui/core/InputLabel";

import {
  curateRole,
  curateCapablility,
} from "../../../actions/curatorActionCreator";
import {
  roleCurator,
  roleMapper,
  roleCapabilities,
} from "../../../helpers/curatorService";
import { logout } from "../../../actions/loginActionCreator";
import Typography from "@material-ui/core/Typography";

import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../services/auth.config';
import { getLoggedInUser } from "../../../helpers/utils";
const LoginForm = (props) => {
  const { classes } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const form = useRef();
  const [state, setState] = useState({
    showPassword: false,
    error: null,
    password: "",
    username: "",
    formError: null,
    formEmailError: null,
    formPasswordError: null,
    loading: false,
  });
  const [email, setEmail] = useState(null)

  const dispatch = useDispatch();
  const { isEmail } = validator;


  //view password function
  const viewPassword = (showPassword) => {
    let data = showPassword === true ? false : true;
    setState({ ...state, showPassword: data });
  };

  //Form error message
  const formErrorMsg = (data) => {
    setState({ ...state, ...data });
  };
  const { instance } = useMsal();
  const account =  getLoggedInUser(instance);
  console.log(account)
  useEffect(() => {
    if (account && account?.username && !email) {
      setEmail(account.username) // Set the active account
    }else {
      return false
    }
  },[account]);

  useEffect(() => {
    if(email){
      AuthService.login(email).then(
        (data) => {
          if (data.isActive) {
            const userRoles = data.roles;
            const capabilities = roleCurator(userRoles);
            const curatedRoles = roleCapabilities(capabilities, userRoles);

            // curateRole(capabilities))
            const curatedCapabilities = roleMapper(capabilities, userRoles);

            dispatch(curateRole(curatedRoles))
              .then(() => {
                dispatch(curateCapablility(curatedCapabilities));
              })
              .catch((e) => {
                console.log(e);
                alert("user details not found. Loggin user out");
                dispatch(logout()).then(() => {
                  history.push("/login");
                });
              });
          }

          dispatch(authForm(data));
        },
        (error) => {
          if (typeof error !== "string") {
            error = "Service is down. Please try again later.";
          }
          //const e = error.response.data.error;
          setState({ ...state, loading: false, formError: error });
        }
      );
    }else {
      return false
    }
  }, [email]);
  const handleLoginRedirect = evt => {
    /**
     * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
     * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
     * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
     */
    evt.preventDefault();
    instance.loginRedirect(loginRequest).then(data => console.log(data,1111)).catch(error => console.log(error,222));
  };

  return (
    <form className={classes.form} noValidate ref={form}>

      <Backdrop className={classes.backdrop} open={state.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      
      <Container maxWidth="xs" className={classes.submitContainer}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleLoginRedirect}
        >
          {t("loginForm.sumitButton.label")}
        </Button>
      </Container>
      <Container maxWidth="xs" className={classes.textContainer}>
        <h3>{t("login.SignUpLink.text")} </h3>

        <p>{t("loginForm.fom.text.paragraph")}</p>
        <h4>{t("loginForm.fom.text.heading4")}</h4>
        <ol>
          <li>{t("loginForm.fom.text.list1")}</li>
          <li>{t("loginForm.fom.text.list2")}</li>
          <li>{t("loginForm.fom.text.list3")}</li>
        </ol>
        <Link href="mailto:?subject=Request%20for%20Unilever%20email%20ID%20to%20access%20Kritique&body=I%20am%20writing%20to%20request%20a%20Unilever%20email%20ID%20for%20me%20so%20that%20I%20can%20access%20Kritique%20using%20SSO.%20Unilever%20IDs%20can%20be%20generated%20by%20accessing%20this%20link%20https%3A%2F%2Funilever.service-now.com%2Fesc%3Fid%3Dsc_cat_item%26sys_id%3Daf760322db76d010d21b055cd396198c%0A%0Amailto:?subject=Request%20for%20Unilever%20email%20ID%20to%20access%20Kritique&body=I%20am%20writing%20to%20request%20a%20Unilever%20email%20ID%20for%20me%20so%20that%20I%20can%20access%20Kritique%20using%20SSO.%20Unilever%20IDs%20can%20be%20generated%20by%20accessing%20this%20link%20https%3A%2F%2Funilever.service-now.com%2Fesc%3Fid%3Dsc_cat_item%26sys_id%3Daf760322db76d010d21b055cd396198c%0A%0A" variant="body2" className={classes.SignUpLink}>
          
          <Typography
            component="span"
            className={classes.signupText}
          >
            {t("login.SignUpLink.signupText")}
          </Typography>
        </Link>
      </Container>
    </form>
  );
};
LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default LoginForm;
