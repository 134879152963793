import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import LoginForm from "../../components/organisms/loginForm/loginForm";
import UseStyles from "./style";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/img/kritique-logo.svg";
import ULogo from "../../assets/img/unilever_logo.svg";
import { Redirect, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

function LogIn() {
  const classes = UseStyles();
  const { Auth } = useSelector((state) => state);
  const { t } = useTranslation();
  const history = useHistory();

  if (Auth.isLoggedIn) {
    if (Auth.user.isActive) {
      if (history.location && history.location.state && history.location.state.from) {
        history.push({ pathname: history.location.state.from});
      } else {
        return <Redirect to="/dashboard" />;
      }
    } else {
      return <Redirect to="/access-request" />;
    }
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <CssBaseline />
      <img src={ULogo} className={classes.bgLogo} alt="Unilever Logo" />
      
      <Container maxWidth="xs" className={classes.loginContainer}>
      <Container maxWidth="xs" className={classes.loginAccessContainer}>
          {/* <Button
              type="button"
              variant="contained"
              color="primary"
              //disabled={!state.username || !state.password ? true : false}
            >
              {t("loginForm.accessBanner.button")}
            </Button> */}
          <Typography
            component="h2"
          variant="h2"
          gutterBottom
          className={classes.h2}
          >
            {t("loginForm.accessBanner.heading")}
        </Typography>
        <Typography
          component="div"
          variant="div"
          align="center"
        >
          {t("loginForm.accessBanner.text")}
        </Typography>
        {/* <Typography
          component="span"
          variant="span"
          align="center"
        >
          {t("loginForm.accessBanner.text.span")}
        </Typography> */}
      </Container>
        <Container maxWidth="xs" className={classes.login}>
          <div className={classes.paper}>
            <img src={Logo} className={"App-logo"} alt="logo" width={130} height={35} />
            <LoginForm classes={classes} />
            <Container component="span" className={classes.copyText}>
                &#169; {new Date().getFullYear()} Unilever
            </Container>
          </div>
          </Container>
        </Container>
    </Container>
  );
}

export default LogIn;
